const navButton = document.querySelector("#nav-hamburger");
const navContent = document.querySelector("#nav-content");
const copyright = document.querySelector("#copyright");
const walletButton = document.querySelector("#wallet-address-button");

if (!!walletButton) {
  walletButton.addEventListener("click", () => {
    navigator.clipboard.writeText(
      walletButton.querySelector("#address-span").innerHTML
    );
    walletButton.classList.toggle("copied");
    setTimeout(() => {
      walletButton.classList.toggle("copied");
    }, 3000);
  });
}

copyright.innerHTML = new Date().getFullYear();

navButton.addEventListener("click", () => {
  if (navButton.classList.contains("closed")) {
    navButton.classList.remove("closed");
    navButton.classList.add("open");
    navContent.classList.remove("closed");
    navContent.classList.add("open");
  } else {
    navButton.classList.add("closed");
    navButton.classList.remove("open");
    navContent.classList.add("closed");
    navContent.classList.remove("open");
  }
});
